<template>
  <div class="fill-height mt-5 py-3 py-md-10">
    <v-container>
      <h1> {{ $t('accountSetting.accountSetting') }}</h1>
      <h3>Change your profile and account setting</h3>
      <div class="mt-5" style="border: 1px solid grey">
        <div
          class="pa-1 mt-3 d-flex justify-space-between align-center"
          style="background: #dadada"
        >
          <h3 class="pl-10"><v-icon> mdi-account </v-icon> Profile</h3>
          <div v-if="isNotEdit">
            <v-btn
              @click="isNotEdit = false"
              class="cust-border-btn-color"
              block
              ><v-icon> mdi-pencil-outline </v-icon> {{ $t('general.edit') }}
            </v-btn>
          </div>
          <div class="d-flex" v-if="!isNotEdit">
            <v-btn @click="isNotEdit = true" class="cust-border-btn-color"
              >{{$t('general.cancel')}}
            </v-btn>
            <v-btn @click="saveChange" class="ml-2 cust-btn-color"
              ><v-icon> mdi-download </v-icon> {{ $t('general.save') }}
            </v-btn>
          </div>
        </div>
        <div class="pa-3">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col lg="4" cols="12">
                <label>{{ $t('accountSetting.firstName') }}</label>
                <v-text-field
                  dense
                  :outlined="true"
                  v-model="form.firstname"
                  :rules="rules"
                  required
                  :disabled="isNotEdit"
                  :placeholder="$t('accountSetting.firstName')"
                ></v-text-field>
              </v-col>
              <v-col lg="4" cols="12">
                <label>Middle name</label>
                <v-text-field
                  dense
                  :outlined="true"
                  v-model="form.middlename"
                  :disabled="isNotEdit"
                  placeholder="Middle name"
                ></v-text-field>
              </v-col>
              <v-col lg="4" cols="12">
                <label>{{ $t('accountSetting.lastName') }}</label>
                <v-text-field
                  dense
                  :outlined="true"
                  v-model="form.lastname"
                  :rules="rules"
                  required
                  :disabled="isNotEdit"
                  :placeholder="$t('accountSetting.lastName')"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col lg="6" cols="12">
                <label>Company</label>
                <v-text-field
                  dense
                  :outlined="true"
                  :disabled="isNotEdit"
                  v-model="form.company"
                  placeholder="Company"
                ></v-text-field>
              </v-col>
              <v-col lg="6" cols="12">
                <label>{{ $t('accountSetting.email') }}</label>
                <v-text-field
                  dense
                  :outlined="true"
                  :disabled="isNotEdit"
                  :rules="rules"
                  required
                  v-model="form.email"
                  :placeholder="$t('accountSetting.email')"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col lg="6" cols="12">
                <label>{{ $t('accountSetting.phoneNumber') }}</label>
                <v-text-field
                  dense
                  :outlined="true"
                  :disabled="isNotEdit"
                  :rules="rules"
                  required
                  v-model="form.phone"
                  :placeholder="$t('accountSetting.phoneNumber')"
                ></v-text-field>
              </v-col>
              <v-col lg="6" cols="12">
                <label>Fax</label>
                <v-text-field
                  dense
                  :outlined="true"
                  :disabled="isNotEdit"
                  v-model="form.fax"
                  placeholder="Fax"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </div>
        <div class="pa-2 mt-3" style="background: #dadada">
          <h3 class="pl-10"><v-icon> mdi-map-marker </v-icon> {{ $t('accountSetting.addressSetting') }}</h3>
        </div>
        <div class="pa-3">
          <Address :isAccountSetting="true"></Address>
        </div>
      </div>

      <div v-if="fbAuth == ''" class="mt-5" style="border: 1px solid grey">
        <div class="pl-3 py-3 mt-3" style="background: #dadada">
          <h3 class="pl-10"><v-icon> mdi-lock-outline </v-icon> {{ $t('password.password') }}</h3>
        </div>
        <div class="pa-3">
          <div v-if="!isChangePassword">
            <v-btn @click="isChangePassword = true" class="cust-btn-color">
             {{ $t('password.changePassword') }}
            </v-btn>
          </div>
          <div v-if="isChangePassword">
            <v-form ref="password" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="6">
                  <label> {{ $t('password.currentPassword') }}</label>
                  <v-text-field
                    type="password"
                    dense
                    :rules="rules"
                    :outlined="true"
                    v-model="current"
                    required
                    :placeholder="$t('password.currentPassword')"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <label>{{ $t('password.newPassword') }}</label>
                  <v-text-field
                    type="password"
                    dense
                    :rules="rules"
                    :outlined="true"
                    v-model="newPassword"
                    required
                    :placeholder="$t('password.newPassword')"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <label>{{ $t('password.confirmPassword') }}</label>
                  <v-text-field
                    type="password"
                    dense
                    :rules="rules"
                    :outlined="true"
                    v-model="confirmPassword"
                    required
                    :placeholder="$t('password.confirmPassword')"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-btn
                @click="isChangePassword = false"
                class="cust-border-btn-color mr-2"
              >
                {{$t('general.cancel')}}
              </v-btn>
              <v-btn @click="changePassword" class="cust-btn-color"
                ><v-icon> mdi-download </v-icon>  {{$t('general.save')}}
              </v-btn>
            </v-form>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import Service from '../services'
import Address from '../components/Address/MainAddress.vue'
import { mapState } from 'vuex'
export default {
  name: 'AccountSetting',
  metaInfo: {
    title: `Thai bike parts`,
    titleTemplate: 'Account Setting | %s'
  },
  data() {
    return {
      valid: true,
      form: {
        firstname: null,
        middlename: null,
        lastname: null,
        email: null,
        fax: null,
        company: null,
        phone: null
      },
      current: '',
      rules: [(v) => !!v || 'This Field is required'],
      newPassword: '',
      confirmPassword: '',
      isNotEdit: true,
      isChangePassword: false
    }
  },
  components: {
    Address
  },
  computed: {
    ...mapState({
      accessToken: (state) => state.Auth.userDetail.access_token,
      fbAuth: (state) => state.Auth.fbAuth.status,
      is_address: (state) => state.Auth.userDetail.is_address,
      profile: (state) => state.Auth.userDetail
    })
  },
  methods: {
    changePassword() {
      if (this.$refs.password.validate()) {
        if (this.newPassword !== this.confirmPassword) {
          this.$swal.fire({
            ...this.$swalError,
            text: 'Please re-type password to confirm'
          })
          this.confirmPassword = ''
          return
        } else {
          Service.auth
            .changePassword({
              access_token: this.accessToken,
              current_password: this.current,
              new_password: this.newPassword
            })
            .then((res) => {
              if (res.status) {
                this.$swal.fire({
                  ...this.$swalSuccess,
                  text: 'Change Password Completed'
                })
                this.$store.dispatch('Auth/logOut')
                this.$store.dispatch('Cart/dismissDialog')
                window.location = '/'
              }
            })
        }
      }
    },
    saveChange() {
      if (this.$refs.form.validate()) {
        Service.auth
          .editProfile({
            access_token: this.accessToken,
            email: this.form.email,
            firstname: this.form.firstname,
            phone: this.form.phone,
            lastname: this.form.lastname,
            middlename: this.form.middlename,
            fax: this.form.fax,
            company: this.form.company
          })
          .then((res) => {
            if (res.status) {
              this.$store.commit('Auth/setUserDetail', res.data)
              this.isNotEdit = true
              this.$swal.fire({
                ...this.$swalSuccess,
                text: 'Completed'
              })
              this.form = this.profile
            }
          })
      }
    }
  },
  created() {
    this.form.firstname = this.profile.firstname
    this.form.lastname = this.profile.lastname
    this.form.email = this.profile.email
    this.form.phone = this.profile.phone
    this.form.fax = this.profile.fax
    this.form.middlename = this.profile.middlename
    this.form.company = this.profile.company
  }
}
</script>

<style>
</style>